@import "constants";

#feature-section-1 {
  margin-top: 102px;
}

$body-line-2-height: 270px;
$body-line-2-top: 285px;

#body-line-2 {
  width: $body-line-size;
  height: $body-line-2-height;
  left: $body-line-left;
  top: $body-line-2-top;
}

#body-line-box-2 {
  left: $body-line-left;
  right: $body-line-right;
  border-top: $body-line-size solid $gold;
  border-right: $body-line-size solid $gold;
  height: 130px;
  top: calc(#{$body-line-2-height} + #{$body-line-2-top});
}

#fs1-split-row {
  display: flex;
  justify-content: space-between;
  padding-right: $body-line-right;

  .feature-description {
    max-width: 400px;
  }

  .illustration-image {
    margin-top: 30px;
  }
}

#fs1-mobile-img {
  display: none;
}

@media (max-width: $mobile-screen-width) {
  #feature-section-1 {
    margin-top: 50px;

    .feature-title {
      padding: 0 $mobile-content-horizontal-padding;
    }
  }

  #body-line-2,
  #body-line-box-2 {
    display: none;
  }

  #fs1-split-row {
    padding: 0 $mobile-content-horizontal-padding;

    div:last-of-type {
      display: none;
    }
  }

  #fs1-mobile-img {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
