#feature-section-1 {
  margin-top: 102px;
}

#body-line-2 {
  width: 3px;
  height: 270px;
  top: 285px;
  left: 180px;
}

#body-line-box-2 {
  height: 130px;
  border-top: 3px solid #9e7509;
  border-right: 3px solid #9e7509;
  top: 555px;
  left: 180px;
  right: 230px;
}

#fs1-split-row {
  justify-content: space-between;
  padding-right: 230px;
  display: flex;
}

#fs1-split-row .feature-description {
  max-width: 400px;
}

#fs1-split-row .illustration-image {
  margin-top: 30px;
}

#fs1-mobile-img {
  display: none;
}

@media (max-width: 1140px) {
  #feature-section-1 {
    margin-top: 50px;
  }

  #feature-section-1 .feature-title {
    padding: 0 20px;
  }

  #body-line-2, #body-line-box-2 {
    display: none;
  }

  #fs1-split-row {
    padding: 0 20px;
  }

  #fs1-split-row div:last-of-type {
    display: none;
  }

  #fs1-mobile-img {
    justify-content: center;
    margin-top: 20px;
    display: flex;
  }
}

/*# sourceMappingURL=index.47b36e09.css.map */
